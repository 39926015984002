const bulkactionsids = [
    {
        key: 1,
        label: "Activate",
      },
      {
        key: 0,
        label: "Inactive",
      },
];

export { bulkactionsids };