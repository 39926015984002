const customproduct = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "imageUrl",
    label: "Image",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "price",
    label: "price",
  },

  {
    key: "actions",
    label: "Actions",
  },
];
export { customproduct };
